import Axios from "axios";

export async function addComment(data) {
  try {
    const response = await Axios.post(
      `https://farasha.smartiniaclient.com/reviews/products/`,
      data
    );
    return response;
  } catch (error) {
    return { error, isError: true };
  }
}

export async function deleteComment(commentId) {
  try {
    const response = await Axios.delete(
      `https://farasha.smartiniaclient.com/reviews/products/${commentId}`
    );
    return response;
  } catch (error) {
    return { error, isError: true };
  }
}
export async function editComment(data) {
  try {
    const response = await Axios.patch(
      `https://farasha.smartiniaclient.com/reviews/products/${data.commentId}/`,
      { comment: data.detail, rating: data.rating, title: data.title }
    );
    return response;
  } catch (error) {
    return { error, isError: true };
  }
}
