import Axios from "axios";

export async function search(data) {
  try {
    const response = await Axios.get(
      `https://farasha.smartiniaclient.com/store/products/?search=${data}`
    );
    return response;
  } catch (error) {
    return { error, isError: true };
  }
}
