import React from "react";
import CheckOut from "./content/CheckOut";
import { Link, useHistory } from "react-router-dom";

import { IconContext } from "react-icons";
import { RiFileAddLine } from "react-icons/ri";
import { BiArrowBack } from "react-icons/bi";

const Page = () => {
  const history = useHistory();

  return (
    <div className="my-0 mx-auto h-auto font-almarai">
      <div className="left-0 right-0 absolute">
        <div className="w-full pt-2">
          <div className="flex flex-row items-center justify-between x-sm:px-5 md:px-20">
            <div
              onClick={() => {
                history.goBack();
              }}
              className="cursor-pointer"
            >
              <IconContext.Provider
                value={{ size: "5em", style: { color: "#d29e83" } }}
              >
                <BiArrowBack></BiArrowBack>
              </IconContext.Provider>
            </div>
            <Link to="/" className="self-end">
              <img
                src="/images/logo.png"
                alt="Logo"
                className="w-20 h-auto mx-auto"
              />
            </Link>
            <div className="opacity-0 x-sm:hidden md:block">
              <img
                src="/images/logo.png"
                alt="Logo"
                className="w-20 h-auto mx-auto"
              />
            </div>
          </div>
        </div>
        <CheckOut></CheckOut>
      </div>
    </div>
  );
};

export default Page;
