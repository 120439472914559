import React from "react";
import Navbar from "./../Navbar";
import Footer from "./../Footer";
import MyOrders from "./MyOrders";

const ProductDetails = () => {
  return (
    <>
      <Navbar></Navbar>
      <MyOrders />
      <Footer></Footer>
    </>
  );
};

export default ProductDetails;
