import React, { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { storeContext } from "./../global/store";
import { deleteTheUserAndTokenFromStorage } from "./../services/auth";
import { IconContext } from "react-icons";
import { AiFillSetting } from "react-icons/ai";
import { FiLogOut } from "react-icons/fi";
import { FaChalkboardTeacher, FaListAlt } from "react-icons/fa";
import { AiFillHeart } from "react-icons/ai";
import { FaSellsy } from "react-icons/fa";
import { TiShoppingCart } from "react-icons/ti";
import { MdExpandMore } from "react-icons/md";
import { MdExpandLess } from "react-icons/md";
import { CgProfile } from "react-icons/cg";
import cn from "classnames";

const Navbar = () => {
  const { store, setStore } = useContext(storeContext);
  const [menuOpen, setMenuOpen] = useState(false);
  const [dropDown, setDropDown] = useState(false);

  let menuStyle = cn(
    "relative cursor-pointer flex flex-col items-center justify-center md:ml-2 2xl:ml-4",
    { dropdown: menuOpen }
  );

  let menuItemsStyle = cn(
    "md:text-sm 2xl:text-2xl bg-gray-100 flex flex-col items-center justify-center space-y-2 md:w-28 2xl:w-48 rounded-lg md:p-2 2xl:p-4 absolute hidden",
    { dropdownContent: menuOpen }
  );

  const changeVisibility = () => {
    const navToggle = document.getElementsByClassName("toggle");
    for (let i = 0; i < navToggle.length; i++) {
      navToggle.item(i).classList.toggle("hidden");
    }

    const navBarToggle = document.getElementsByClassName("nav__item");
    for (let i = 0; i < navBarToggle.length; i++) {
      navBarToggle.item(i).classList.toggle("hidden__nav");
    }
  };

  //Close Side Bar on Exterior Clicks
  const DropRef = useRef();
  function handleClickOutside(event) {
    if (DropRef.current && !DropRef.current.contains(event.target)) {
      setDropDown(() => false);
    }
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return function cleanup() {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="x-sm:p-1 md:p-6 text-center text-blue-night font-extrabold bg-gray-100 md:h-24 flex flex-col-reverse md:flex-row md:justify-around items-center md:text-xs lg:text-sm xl:text-base 2xl:text-2xl">
      <div
        style={{ zIndex: "1000" }}
        className="hidden__nav nav__item w-full x-sm:bg-gray-100 md:bg-transparent p-2 flex flex-col-reverse items-center md:flex-row md:justify-around xl:space-x-10  lg:space-x-6 md:space-x-2 space-y-2 md:space-y-0"
      >
        <div className="md:block md:float-left  w-1/3 flex ">
          <div className="flex flex-col md:flex-row xl:space-x-10 lg:space-x-6 md:space-x-2 space-y-2 md:space-y-0 items-center justify-center">
            {!store.isLogged && (
              <a
                href="https://farashawake.com/signup"
                target="_blank"
                rel="noreferrer"
                className="bg-blue-night text-white rounded-full p-2 flex flex-row x-sm:mt-4 md:mt-0"
              >
                إنضم إلينا
              </a>
            )}
            {!store.isLogged && (
              <Link to="/login" className="">
                تسجيل الدخول
              </Link>
            )}
            {store.isLogged && (
              <Link to="/cart" className="x-sm:text-xs md:text-base">
                <IconContext.Provider
                  value={{ size: "2em", style: { color: "#160c3e" } }}
                >
                  <TiShoppingCart></TiShoppingCart>
                </IconContext.Provider>
                {/* <span>الملف الشخصي</span> */}
              </Link>
            )}
            {store.isLogged && (
              <div className="x-sm:text-xs md:text-base">
                <div className={menuStyle}>
                  <div
                    onClick={() => {
                      setMenuOpen(!menuOpen);
                    }}
                  >
                    <IconContext.Provider
                      value={{ size: "2em", style: { color: "#160c3e" } }}
                    >
                      <CgProfile></CgProfile>
                    </IconContext.Provider>
                    {/* <span>الملف الشخصي</span> */}
                  </div>
                  <div className={menuItemsStyle}>
                    <Link
                      to="/favourite"
                      className="text-blue-night flex flex-row space-x-1 items-center justify-center cursor-pointer"
                    >
                      <span className="">مفضلاتي</span>
                      <IconContext.Provider
                        value={{ size: "1em", style: { color: "#160c3e" } }}
                      >
                        <AiFillHeart></AiFillHeart>
                      </IconContext.Provider>
                    </Link>
                    <Link
                      to="/my-orders"
                      className="text-blue-night flex flex-row space-x-1 items-center justify-center cursor-pointer"
                    >
                      <span className="">طلباتي</span>
                      <IconContext.Provider
                        value={{ size: "1em", style: { color: "#160c3e" } }}
                      >
                        <FaSellsy></FaSellsy>
                      </IconContext.Provider>
                    </Link>
                  </div>
                </div>
              </div>
            )}
            {store.isLogged && (
              <div
                onClick={() => {
                  setStore({
                    ...store,
                    isLogged: false,
                  });
                  deleteTheUserAndTokenFromStorage();
                }}
                // to="/"
                className="cursor-pointer x-sm:text-xs md:text-base"
              >
                <IconContext.Provider
                  value={{ size: "2em", style: { color: "#160c3e" } }}
                >
                  <FiLogOut></FiLogOut>
                </IconContext.Provider>
                {/* تسجيل الخروج */}
              </div>
            )}
            <div className="text-gray-100">---------</div>
          </div>
        </div>
        <Link to="/" className="hidden md:block  w-1/3 flex justify-center">
          <img src="/images/logo.png" alt="Logo" className="w-20 mx-auto" />
        </Link>
        <div className="md:block md:float-right w-1/3 justify-center">
          <div className="flex flex-col-reverse justify-center md:flex-row xl:space-x-8 lg:space-x-6 md:space-x-2 space-y-2 md:space-y-0 items-center ">
            <div ref={DropRef}>
              <div
                className="relative cursor-pointer flex items-center space-x-1"
                onClick={() => setDropDown(!dropDown)}
              >
                <span>أصناف</span>
                <IconContext.Provider
                  value={{ size: "1em", style: { color: "#160c3e" } }}
                >
                  {dropDown ? (
                    <MdExpandLess></MdExpandLess>
                  ) : (
                    <MdExpandMore></MdExpandMore>
                  )}
                </IconContext.Provider>
              </div>
              {dropDown && (
                <div className="absolute bg-white rounded-md flex flex-col p-4 space-y-4 border shadow-md">
                  <Link to="/category/all" onClick={() => setDropDown(false)}>
                    جميع المنتجات
                  </Link>
                  <Link to="/category/hot" onClick={() => setDropDown(false)}>
                    الأكثر مبيعا
                  </Link>
                  <Link to="/category/new" onClick={() => setDropDown(false)}>
                    الجديدة
                  </Link>
                  <Link to="/category/books" onClick={() => setDropDown(false)}>
                    الكتب
                  </Link>
                  <Link
                    to="/category/stones"
                    onClick={() => setDropDown(false)}
                  >
                    الأحجار الطاقية
                  </Link>
                </div>
              )}
            </div>
            <Link to="/">الرئيسية</Link>
          </div>
        </div>
      </div>
      <div className="md:hidden self-end">
        <button
          id="hamburger"
          onClick={(e) => changeVisibility(e)}
          style={{ border: "none", outline: "none" }}
        >
          <img
            className="toggle block"
            src="https://img.icons8.com/160c3e/fluent-systems-regular/2x/menu-squared-2.png"
            alt="menu"
            width="40"
            height="40"
          />
          <img
            className="toggle hidden"
            src="https://img.icons8.com/160c3e/fluent-systems-regular/2x/close-window.png"
            alt="quite"
            width="40"
            height="40"
          />
        </button>
      </div>
    </div>
  );
};

export default Navbar;
