import React, { useState } from "react";
import ProductCard from "./ProductCard";

import useSWR, { mutate } from "swr";
import { fetcher } from "./../../services/fetcher";
import { Link, useLocation } from "react-router-dom";

const Products = ({ title, id }) => {
  const { pathname } = useLocation();
  const [page, setPage] = useState(0);

  // const { data, error } = useSWR(
  //   `https://farasha.smartiniaclient.com/store/categories/${id}${
  //     pathname === "/" ? "?limit=10" : ""
  //   }`,
  //   fetcher
  // );

  const { data, error } = useSWR(
    pathname === "/"
      ? `https://farasha.smartiniaclient.com/store/products/?category=${id}&page=${
          page + 1
        }`
      : `https://farasha.smartiniaclient.com/store/products/?category=${id}&page=${
          page + 1
        }`,
    fetcher
  );

  return (
    <div className="h-full x-sm:p-4 md:px-20 space-y-8">
      <div className="bg-reghda-blue rounded-lg w-full text-center text-white px-2 py-2 md:text-xl lg:text-2xl 2xl:text-4xl">
        <h1>{title}</h1>
      </div>
      <div className="w-full h-full grid x-sm:grid-cols-2 sm:grid-cols-3 md:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-4">
        {data &&
          data.results &&
          data.results.map((product) => (
            <ProductCard product={product}></ProductCard>
          ))}
      </div>

      <div className="cursor-pointer bg-reghda-blue text-white rounded-md px-4 py-2 text-center w-1/2 lg:w-1/6 mx-auto font-semibold">
        <Link to="/category/books">شاهد المزيد</Link>
      </div>

      {/* <div className="flex flex-row space-x-4 items-center justify-end md:text-base 2xl:text-2xl">
        <p className="bg-reghda-blue px-2 rounded-l-2xl text-white cursor-pointer">
          السابق
        </p>
        <p className="text-reghda-blue">1</p>
        <p className="bg-reghda-blue px-2 rounded-r-2xl text-white cursor-pointer">
          التالي
        </p>
      </div> */}
    </div>
  );
};

export default Products;
