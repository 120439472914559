import React from "react";
import Navbar from "./../Navbar";
import Footer from "./../Footer";
import Toolbar from "./../store-page/Toolbar";
import Products from "./../store-page/Products";
import { useParams } from "react-router-dom";

const StoreTypePage = () => {
  const { type } = useParams();

  const typeToId = () => {
    switch (type) {
      case "hot":
        return 4;
      case "new":
        return 5;
      case "books":
        return 8;
      case "stones":
        return 7;
      case "all":
        return 9;
      default:
        return 4;
    }
  };

  const typeToName = () => {
    switch (type) {
      case "hot":
        return "الأكثر مبيعا";
      case "new":
        return "الجديدة";
      case "books":
        return "الكتب";
      case "stones":
        return "الأحجار الطاقية";
      case "all":
        return "جميع المنتجات";
      default:
        return "الأكثر مبيعا";
    }
  };

  return (
    <>
      <Navbar></Navbar>
      <Toolbar></Toolbar>
      {/* Products By Categories */}
      <Products title={typeToName()} id={typeToId()}></Products>
      {/* Display All */}
      <Footer></Footer>
    </>
  );
};

export default StoreTypePage;
