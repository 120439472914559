import Axios from "axios";

export async function login(data) {
  const formData = new FormData();
  formData.append("email", data.email);
  formData.append("password", data.password);
  const res = await fetch(`https://farasha.smartiniaclient.com/users/token`, {
    method: "POST",
    body: formData,
  });
  const jsonData = await res.json();
  const status = await res.status;
  return { status, data: jsonData };
}

export async function loginWithGoogle(data) {
  try {
    const response = await Axios.post(
      `https://farasha.smartiniaclient.com/users/social-auth`,
      data
    );
    return response;
  } catch (error) {
    return { error, isError: true };
  }
}

export async function signup(data) {
  try {
    const response = await Axios.post(
      `https://farasha.smartiniaclient.com/users/register`,
      data
    );
    return response;
  } catch (error) {
    return { error, isError: true };
  }
}

export function storeTheUser(data, token, refresh) {
  localStorage.setItem("token", token);
  localStorage.setItem("refresh", refresh);
  localStorage.setItem("user", JSON.stringify(data));
}

export function getTheUserFromStorage() {
  return JSON.parse(localStorage.getItem("user"));
}

export function getTheTokenFromStorage() {
  return localStorage.getItem("token");
}

export function getTheRefresh() {
  return localStorage.getItem("refresh");
}

export function deleteTheUserAndTokenFromStorage() {
  localStorage.removeItem("token");
  localStorage.removeItem("refresh");
  localStorage.removeItem("user");
}
