import React from "react";
import Navbar from "./../Navbar";
import Footer from "./../Footer";
import Toolbar from "./Toolbar";
import Show from "./Show";
import Products from "./Products";
import EnergyStones from "./EnergyStones";
import NewProducts from "./NewProducts";
import MostSold from "./MostSold";
import Books from "./Books";

const StorePage = () => {
  return (
    <>
      <Navbar></Navbar>
      <Toolbar></Toolbar>
      <Show></Show>
      <MostSold title="الأكثر مبيعا" id={4}></MostSold>
      <NewProducts title="الجديدة" id={5}></NewProducts>
      <Books title="الكتب" id={8}></Books>
      <EnergyStones title="الأحجار الطاقية" id={7}></EnergyStones>
      <Footer></Footer>
    </>
  );
};

export default StorePage;
