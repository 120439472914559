import React from "react";
import Navbar from "./../Navbar";
import Footer from "./../Footer";
import Cart from "./content/Cart";

const Page = () => {
  return (
    <div className="my-0 mx-auto h-auto font-almarai">
      <div className="left-0 right-0 absolute">
        <Navbar></Navbar>
        <Cart></Cart>
        <Footer></Footer>
      </div>
    </div>
  );
};

export default Page;
