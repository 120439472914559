import React, { useState } from "react";
import Navbar from "./../Navbar";
import Footer from "./../Footer";
import { Link } from "react-router-dom";

const Questions = () => {
  return (
    <div>
      <Navbar></Navbar>
      <div className="h-full text-center p-16 flex flex-col items-center justify-center x-sm:space-y-4 md:space-y-8 2xl:space-y-16 text-blue-night">
        <h1 className="font-extrabold text-orange x-sm:text-xs sm:text-base md:text-xl lg:text-2xl 2xl:text-5xl">
          أسئلة متكررة
        </h1>
        <div className="text-right x-sm:text-xxs sm:text-sm md:text-lg lg:text-xl 2xl:text-3xl leading-loose space-y-6">
          <h1 className="font-extrabold text-orange leading-loose x-sm:text-xs sm:text-base md:text-xl lg:text-2xl 2xl:text-4xl break-words">
            كيف أستطيع شراء البرامج والدورات والمنتجات؟
          </h1>
          <p className="font-medium leading-loose break-words">
            تستطيع الشراء بسهولة عبر موقعنا: باستخدام طرق الدفع التالية: فيزا/
            ماستر كارد/ باي بال. وفي حال الرغبة في مساعدة أكثر يسعدنا تواصلك
            معنا لتزويدك بطرق اخرى للدفع كالتحويل البنكي او الويسترن يونيون
          </p>
          <h1 className="font-extrabold text-orange leading-loose x-sm:text-xs sm:text-base md:text-xl lg:text-2xl 2xl:text-4xl break-words">
            هل الدورات حضورية أو أون لاين ؟
          </h1>
          <p className="font-medium leading-loose break-words">
            جميع دوراتنا أون لاين )مسجلة( وليست مباشرة ويمكنك حضورها من بيتك في
            أي وقت
          </p>
          <h1 className="font-extrabold text-orange leading-loose x-sm:text-xs sm:text-base md:text-xl lg:text-2xl 2xl:text-4xl break-words">
            أين أ جد البرامج والكتب والدورات التي اقتنيتها ؟ لم تصلني عبر
            الايميل! ؟
          </h1>
          <p className="font-medium leading-loose break-words">
            ستجدها في حسابك في فراشة اسانشن للتدريب فور اقتنائك لها عبر دخولك
            لحسابك ثم الذهاب لأيقونة حسابي ثم خانة دوراتي للبرامج والدورات وخانة
            كتبي للكتب
          </p>
          <h1 className="font-extrabold text-orange leading-loose x-sm:text-xs sm:text-base md:text-xl lg:text-2xl 2xl:text-4xl break-words">
            هل البرامج والدورات والكتب التي أشتريها لها تاريخ انتهاء ؟
          </h1>
          <p className="font-medium leading-loose break-words">
            لا، ليس لها تاريخ انتهاء، بإمكانك الاستمتاع بها من بيتك في أي وقت
          </p>
          <h1 className="font-extrabold text-orange leading-loose x-sm:text-xs sm:text-base md:text-xl lg:text-2xl 2xl:text-4xl break-words">
            هل بإمكاني تحميل المواد والكتب لجهازي الخاص؟
          </h1>
          <p className="font-medium leading-loose break-words">
            نعم تتوفر خاصية التحميل بشكل مسموع لجميع موادنا
          </p>
          <h1 className="font-extrabold text-orange leading-loose x-sm:text-xs sm:text-base md:text-xl lg:text-2xl 2xl:text-4xl break-words">
            هل من الممكن التواصل معك أستاذة راشا للاستفسار عن البرامج والدورات
            والكتب التي اشتريتها ؟
          </h1>
          <p className="font-medium leading-loose break-words">
            في جميع البرامج والمواد والمنتجات التي أقدمها أحرص على تقديم مادة
            سهلة وواضحة وشاملة، وستجد في مضمون البرنامج أو الدورة الكثير من
            الأسئلة التوضيحية عن الماد ة وأجوبتها، يسعدني التواصل مع الجميع لكن
            في الوقت الحالي يتعذر استقبال الاستفسارات بشكل شخصي بسبب ضخامة عدد
            المستفيدين . بإمكانك ذلك في حال إشتراكك ببرنامج كوني ملكة الحب أو
            الحجز للجلسات العلاجية وجلسات الكوتشنج
          </p>
          <h1 className="font-extrabold text-orange leading-loose x-sm:text-xs sm:text-base md:text-xl lg:text-2xl 2xl:text-4xl break-words">
            هل هذه البرامج والدورات ستساعدني في حل مشاكلي؟
          </h1>
          <p className="font-medium leading-loose break-words">
            نعم هذه البرامج والدورات ستساعدك في حل مشاكلك في حال اختيار المادة
            المناسبة واستيعاب المعلومات وتطبيقها وعمل التمارين الموجودة في
            البرنامج أو الدورة
          </p>
          <h1 className="font-extrabold text-orange leading-loose x-sm:text-xs sm:text-base md:text-xl lg:text-2xl 2xl:text-4xl break-words">
            هل بإمكان شخص آخر حضور البرنامج أو الدورة معي؟
          </h1>
          <p className="font-medium leading-loose break-words">
            لا يُسمَح بمشاركة معلومات الحساب أو إرسال المواد لشخص آخر
          </p>
          <h1 className="font-extrabold text-orange leading-loose x-sm:text-xs sm:text-base md:text-xl lg:text-2xl 2xl:text-4xl break-words">
            هل بإمكاني نشر المعلومات للفائدة؟
          </h1>
          <p className="font-medium leading-loose break-words">
            لا نفضل أن تنشري للأخرين معلومات وقد يكونو غير مستعدين لهذه
            المعلومات لذلك نفضل أن تقومي بتطبيقها في حياتك وعندما يشاهدون
            النتيجة سيحفز ذلك الرغبة لديهم للتعلم والتغيير حيث أن التغيير تحتاج
            الى تسلسل وتدرج وتكامل ويفضل أن تدليهم على منتجاتنا وخدماتنا
            للإستفادة القصوى
          </p>
          <h1 className="font-extrabold text-orange leading-loose x-sm:text-xs sm:text-base md:text-xl lg:text-2xl 2xl:text-4xl break-words">
            هل تحتوي البرامج والدورات والكتب على تمارين وتطبيقات ؟
          </h1>
          <p className="font-medium leading-loose break-words">
            جميع موادنا تحتوي على تمارين وتطبيقات لأنها ضرورية للحصول على
            النتائج المرغوبة. والتمارين والتطبيقات تؤثر على تحسين حياتك وتغييرها
            ٧٠٪ من النتائج لذلك مهم جدا عمل التطبيقات والتمارين للاستفادة
            الحقيقية الكاملة من البرامج
          </p>
          <h1 className="text-center font-extrabold text-orange leading-loose x-sm:text-xs sm:text-base md:text-xl lg:text-2xl 2xl:text-4xl break-words">
            سياسة الإرجاع
          </h1>
          <p className="text-center font-medium leading-loose break-words">
            يرجى الاطلاع على سياسة الإرجاع الخاصة بموقع من هنا
            <span className="text-reghda-blue">
              <Link className="underline text-orange" to="/return">
                {" "}
                سياسة الإرجاع
              </Link>
            </span>
          </p>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default Questions;
