import React, { useState } from "react";
import { clearCart } from "../../../services/cart";
import { getTheUserFromStorage } from "./../../../services/auth";
import { createOrder } from "../../../services/orders";
import { useHistory, Link } from "react-router-dom";
import { toast } from "react-toastify";

const BankTransferButton = ({ setLoading, shippmentDetail }) => {
  const [showInfoBank, setShowInfoBank] = useState(false);
  const [proof, setProof] = useState(null);
  const history = useHistory();

  return (
    <>
      <div className="flex flex-row items-center justify-between py-4">
        <div className="flex flex-row space-x-4 items-center justify-center">
          <img
            src="/images/museum.png"
            className="x-sm:h-6 sm:h-10"
            alt="icon"
          />
          <p className="text-reghda-black text-right x-sm:text-sm sm:text-base lg:text-lg 2xl:text-2xl font-bold">
            تحويل بنكي
          </p>
        </div>
        <p className="text-reghda-black text-right x-sm:text-sm sm:text-base lg:text-lg 2xl:text-2xl font-bold">
          الدفع عبر
        </p>
      </div>
      {!showInfoBank && (
        <button
          onClick={async () => {
            setShowInfoBank(true);
          }}
          className="w-full bg-orange text-white rounded-xl py-2 x-sm:px-2 sm:px-4 md:px-6 lg:px-8 2xl:px-16 2xl:py-4 x-sm:text-sm sm:text-base lg:text-xl 2xl:text-2xl outline-none focus:outline-none"
        >
          مواصلة
        </button>
      )}
      {showInfoBank && (
        <>
          <div className="p-4 bg-gray-100 rounded-xl text-left">
            <div className="mb-6 flex flex-row items-center justify-center space-x-4 font-extrabold">
              <p>ALGERIA</p>
              <img src="/images/dz.png" className="h-10 w-10" alt="dz" />
            </div>
            <p className="mb-6 text-reghda-black">RACHA MECHTA</p>
            <p className="font-extrabold text-gray-500">CCP:</p>
            <p className="mb-6 text-reghda-black">12790356 CLE 10</p>
            <p className="font-extrabold text-gray-500">RIB / BARIDIMOB :</p>
            <p className="mb-6 text-reghda-black break-words">
              00799999001279035610
            </p>
          </div>
          <div className="flex items-center justify-end">
            {proof && (
              <p className="pr-4 text-green-300	x-sm:text-sm sm:text-base lg:text-xl 2xl:text-2xl">
                تم التحميل
              </p>
            )}
            <div className="py-4 rounded-xl">
              <button
                onClick={() => {
                  document.querySelector("#upload-proof").click();
                }}
                className="w-full bg-orange text-white rounded-xl py-2 x-sm:px-2 sm:px-4 md:px-6 lg:px-8 2xl:px-16 2xl:py-4 x-sm:text-sm sm:text-base md:text-lg lg:text-xl 2xl:text-2xl outline-none focus:outline-none"
              >
                تحميل وصل الإيداع
              </button>
              <input
                onChange={(event) => {
                  if (event.target.files[0]) {
                    setProof(event.target.files[0]);
                  }
                }}
                id="upload-proof"
                type="file"
                accept="image/*"
                style={{ display: "none" }}
              />
            </div>
          </div>
          <button
            onClick={async () => {
              if (!proof) {
                toast("يجب عليك تحميل وصل الإيداع");
                return;
              }
              setLoading(true);
              let formData = new FormData();
              formData.append("user", getTheUserFromStorage().user_id);
              formData.append("payment_method", "تحويل بنكي");
              formData.append("payment_receipt", proof);
              formData.append("given_name", shippmentDetail.firstname);
              formData.append("family_name", shippmentDetail.lastname);
              formData.append("country", shippmentDetail.country);
              formData.append("address", shippmentDetail.addressOne);
              formData.append("second_address", shippmentDetail.addressTwo);
              formData.append("phone_number", shippmentDetail.phone);

              const res = await createOrder(formData);
              if (res && res.status === 200) {
                toast("العملية تمت بنجاح 🎉");
                await clearCart(getTheUserFromStorage().user_id);
                setLoading(false);
                history.push("/my-orders");
              } else {
                setLoading(false);
                toast("العملية لم تتم بنجاح ❌");
              }
              setLoading(false);
            }}
            className="w-full bg-orange text-white rounded-xl py-2 x-sm:px-2 sm:px-4 md:px-6 lg:px-8 2xl:px-16 2xl:py-4 x-sm:text-sm sm:text-base lg:text-xl 2xl:text-2xl outline-none focus:outline-none"
          >
            تأكيد
          </button>
        </>
      )}
    </>
  );
};

export default BankTransferButton;
