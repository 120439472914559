import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import LoaderModal from "../../LoaderModal";
// import PayButton from "./PayButton";
import BankTransferButton from "./BankTransferButton";
import ShippmentInfo from "./ShippmentInfo";

const CheckOut = () => {
  const history = useHistory();
  const total = history.location.state.data.total;
  const products = history.location.state.data.products;
  const cartId = history.location.state.data.cartId;

  const [loading, setLoading] = useState(false);
  const [info, setInfo] = useState(false);
  const [shippmentDetail, setShippmentDetail] = useState(null);

  //   let number = 0;
  //   history.location.state.data.ids.map((product) => {
  //     number += product.quantity;
  //     return product;
  //   });

  return (
    <div className="flex flex-col lg:flex-row h-screen">
      <div className="h-screen w-full lg:w-1/2 x-sm:p-2 sm:p-10 lg:p-20">
        <div className="bg-white p-6 rounded-xl">
          <p className="border-b-2 border-orange pb-4 text-orange text-right x-sm:text-sm sm:text-base lg:text-lg 2xl:text-2xl font-bold">
            المشتريات
          </p>
          {products &&
            products.length > 0 &&
            products.map((product, i) => (
              <div
                key={i}
                className="w-full flex flex-row items-center justify-between py-4"
              >
                <p className="w-1/3 text-center text-reghda-black  x-sm:text-sm sm:text-base lg:text-lg 2xl:text-2xl font-bold">
                  {`${product.quantity}`}{" "}
                  <span className="text-red-400 ml-2">x</span>
                </p>
                <p className="w-1/3 text-center flex flex-row items-center justify-center space-x-2 text-reghda-black x-sm:text-sm sm:text-base lg:text-lg 2xl:text-2xl font-bold">
                  <span>دج</span>
                  <span>{`${product.price}`}</span>
                </p>

                <p className="w-1/3 text-center text-reghda-black  x-sm:text-sm sm:text-base lg:text-lg 2xl:text-2xl font-bold">
                  {`${product.name}`}
                </p>
              </div>
            ))}
          <div className="flex flex-row items-center justify-between py-4">
            <p className="flex flex-row items-center justify-center space-x-2 text-reghda-black text-right x-sm:text-sm sm:text-base lg:text-lg 2xl:text-2xl font-bold">
              <span>دج</span>
              <span>800</span>
            </p>
            <p className="text-reghda-pink text-right x-sm:text-sm sm:text-base lg:text-lg 2xl:text-2xl font-bold">
              تكاليف الشحن
            </p>
          </div>
          <div className="flex flex-row items-center justify-between py-4">
            <p className="flex flex-row items-center justify-center space-x-2 text-reghda-black text-right x-sm:text-sm sm:text-base lg:text-lg 2xl:text-2xl font-bold">
              <span>دج</span>
              <span>{`${total + 800}`}</span>
            </p>
            <p className="text-orange text-right x-sm:text-sm sm:text-base lg:text-lg 2xl:text-2xl font-bold">
              المجموع
            </p>
          </div>
        </div>
      </div>
      <div className="h-screen w-full lg:w-1/2 x-sm:p-2 sm:p-10 lg:p-20">
        {!info && (
          <ShippmentInfo
            setShippmentDetail={setShippmentDetail}
            setInfo={setInfo}
          ></ShippmentInfo>
        )}
        {info && (
          <div className="bg-white p-6 rounded-xl">
            <p className="pb-4 text-orange text-right x-sm:text-sm sm:text-base lg:text-lg 2xl:text-2xl font-bold">
              الدفع
            </p>
            {/* <PayButton
              setLoading={setLoading}
              total={total}
              shippmentDetail={shippmentDetail}
            ></PayButton> */}
            {/* <br /> */}
            {/* <br /> */}
            <BankTransferButton
              shippmentDetail={shippmentDetail}
              setLoading={setLoading}
            ></BankTransferButton>
          </div>
        )}
      </div>
      <LoaderModal modalIsOpen={loading}></LoaderModal>
    </div>
  );
};

export default CheckOut;
