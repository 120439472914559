import React, { useContext } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link, Redirect, useHistory } from "react-router-dom";
import { storeContext } from "./../global/store";
import jwt from "jwt-decode";
import { login, storeTheUser, loginWithGoogle } from "./../services/auth";
import { GoogleLogin } from "react-google-login";

import { IconContext } from "react-icons";
import { FcCdLogo, FcGoogle } from "react-icons/fc";

import { toast } from "react-toastify";

const Login = () => {
  const { store, setStore } = useContext(storeContext);
  let history = useHistory();

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("عنوان البريد الإلكتروني غير صالح")
      .required("هذا الحقل مطلوب"),
    password: Yup.string()
      .required("هذا الحقل مطلوب")
      .min(8, "كلمة المرور قصيرة جدًا - يجب ألا تقل عن 8 أحرف."),
  });

  if (store.isLogged) {
    return <Redirect to="/"></Redirect>;
  }

  return (
    <div className="flex flex-row h-screen">
      <div className="hidden md:block bg-purple-400 h-screen w-1/2 overview-sl"></div>
      <div className="h-screen overflow-y-auto x-sm:w-full md:w-1/2 flex flex-col x-sm:space-y-3 md:space-y-6 2xl:space-y-12 text-right text-reghda-black x-sm:px-8 md:px-12 2xl:px-24 x-sm:text-sm md:text-base 2xl:text-3xl">
        <Link to="/">
          <div className="p-4 flex flex-col items-center">
            <img
              className="flex-1 x-sm:w-20 x-sm:h-20 md:w-20 md:h-20 2xl:w-48 2xl:h-48"
              alt="logo"
              src="/images/logo.png"
            />
          </div>
        </Link>
        <p className="md:text-2xl 2xl:text-6xl font-extrabold">تسجيل الدخول</p>
        <p className="md:text-xl 2xl:text-5xl font-medium md:leading-relaxed 2xl:leading-relaxed">
          للقيام بتسجيل الدخول, يرجى إدخال البريد الألكتروني و كلمة السر الخاصين
          بك
        </p>
        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          validationSchema={validationSchema}
          onSubmit={async (values, { setSubmitting }) => {
            const res = await login(values);
            if (res && res.status === 200 && res.data) {
              const token = res.data.access;
              const refresh = res.data.refresh;
              const user = jwt(res.data.access);
              setStore({
                ...store,
                token,
                user,
                isLogged: true,
              });
              storeTheUser(user, token, refresh);
              history.goBack();
            } else {
              toast(res.data.message);
            }
            setSubmitting(false);
          }}
        >
          <Form className="text-white x-sm:space-y-2 md:space-y-4 2xl:space-y-6">
            <Field
              className="bg-orange w-full text-white placeholder-white text-right x-sm:p-1 md:p-2 2xl:p-4 pr-6 rounded-2xl outline-none focus:outline-none  focus:border-pink-300"
              placeholder="البريد الالكتروني"
              name="email"
              type="email"
            />
            <div className="text-red-400 border-red-400 md:text-sm 2xl:text-2xl">
              <ErrorMessage name="email" />
            </div>
            <Field
              className="bg-orange w-full text-white placeholder-white text-right x-sm:p-1 md:p-2 2xl:p-4 pr-6 rounded-2xl outline-none focus:outline-none  focus:border-pink-300"
              placeholder="كلمة السر"
              name="password"
              type="password"
            />
            <div className="text-red-400 border-red-400 md:text-sm 2xl:text-2xl">
              <ErrorMessage name="password" />
            </div>
            <button
              className="bg-blue-night text-white text-center w-full x-sm:p-1 md:p-2 2xl:p-4 pr-6 rounded-2xl shadow-lg"
              type="submit"
            >
              تسجيل الدخول
            </button>

            <GoogleLogin
              clientId="779569317705-3geredfd5a0ekvlpqb5rlov9r1nj58h2.apps.googleusercontent.com"
              render={(renderProps) => (
                <div
                  disabled={renderProps.disabled}
                  onClick={renderProps.onClick}
                  className="cursor-pointer flex flex-row items-center justify-center space-x-2 bg-white text-blue-night text-center w-full x-sm:p-1 md:p-2 2xl:p-4 pr-6 rounded-2xl shadow-2xl"
                >
                  <IconContext.Provider
                    value={{ size: "1em", style: { color: "#fff" } }}
                  >
                    <FcGoogle></FcGoogle>
                  </IconContext.Provider>
                  <p>دخول بغوغل</p>
                </div>
              )}
              buttonText="سجل بغوغل"
              onSuccess={async (res) => {
                let token = res.tokenId;
                let user = { ...jwt(token) };
                let values = {
                  username: user.name,
                  email: user.email,
                  auth_provider: "google.com",
                };

                try {
                  const res = await loginWithGoogle(values);
                  const token = res.data.access;
                  const refresh = res.data.refresh;
                  const user = jwt(res.data.access);

                  if (
                    res.data &&
                    res.data.access &&
                    user.user_id &&
                    res.status === 200
                  ) {
                    setStore({
                      ...store,
                      token,
                      user,
                      isLogged: true,
                    });
                    storeTheUser(user, token, refresh);
                    history.goBack();
                  }
                } catch (e) {
                  toast("هنالك خطاءً ما ❌");
                }
              }}
              onFailure={(res) => {
                // alert("something wrong!, please try again");
              }}
              cookiePolicy={"single_host_origin"}
            />
          </Form>
        </Formik>
        <a href="https://farashawake.com/reset_password">
          <span className="text-blue-night"> نسيت كلمة المرور ؟ </span>
        </a>
        <a href="https://farashawake.com/signup">
          ليس لديك حساب ؟ <span className="text-blue-night">سجل معنا</span>
        </a>
      </div>
    </div>
  );
};

export default Login;

// cid 779569317705-3geredfd5a0ekvlpqb5rlov9r1nj58h2.apps.googleusercontent.com
// secret J6w-dogi8o0x3lrhT5w16z5N
