import React, { useState, useContext, useEffect } from "react";
import { storeContext } from "./../../global/store";

import { useHistory, useParams } from "react-router-dom";
import { IconContext } from "react-icons";
import { TiShoppingCart } from "react-icons/ti";
import { AiFillHeart, AiOutlineHeart, AiOutlinePlus } from "react-icons/ai";
import { RiSubtractLine } from "react-icons/ri";

import Rating from "@material-ui/lab/Rating";

import { Fade, Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

import { getTheUserFromStorage } from "../../services/auth";
import { addToCart } from "../../services/cart";
import LoaderModal from "./../LoaderModal";

import useSWR, { mutate } from "swr";
import { fetcher } from "./../../services/fetcher";
import { toggleToFavourite } from "../../services/favourite";
import Reviews from "./Reviews";

import Zoom from "react-img-zoom";
import { toast } from "react-toastify";

const Details = () => {
  const { slug } = useParams();
  let history = useHistory();
  const { store, setStore } = useContext(storeContext);
  const [loading, setLoading] = useState(false);

  const product = useSWR(
    `https://farasha.smartiniaclient.com/store/details/products/${slug}`,
    fetcher
  );

  const [quantity, setQuantity] = useState(1);

  const [url, setUrl] = useState(
    product.data
      ? `https://farasha.smartiniaclient.com/store/favourites/list/${1}/${
          product.data.id
        }`
      : null
  );

  const { data, error } = useSWR(url, fetcher);

  useEffect(() => {
    if (store.isLogged) {
      product.data &&
        setUrl(
          `https://farasha.smartiniaclient.com/store/favourites/list/${
            getTheUserFromStorage().user_id
          }/${product.data.id}`
        );
    } else {
      product.data &&
        setUrl(
          `https://farasha.smartiniaclient.com/store/favourites/list/${1}/${
            product.data.id
          }`
        );
    }
  }, [product.data, store.isLogged]);

  return (
    <div className="pb-16 md:pt-4">
      {product.data && (
        <h1 className="text-reghda-blue text-center md:text-2xl 2xl:text-4xl font-extrabold">
          {product.data.name}
        </h1>
      )}
      <div className="flex x-sm:flex-col md:flex-row items-center x-sm:justify-center md:justify-around md:px-20 x-sm:py-6 md:py-0">
        <div className="x-sm:w-full md:w-1/3 flex flex-row items-center justify-center">
          <div className="w-full h-full">
            {product.data && product.data.images.length && (
              <Slide transitionDuration={300} autoplay={false}>
                {product.data.images.map((img) => (
                  <div className="each-fade">
                    <div className="h-full w-full flex flex-row items-center justify-center">
                      {/* <img
                        className="h-full w-3/4"
                        src={img}
                        alt="some-detail"
                      /> */}
                      <Zoom img={img} zoomScale={3} width={400} height={400} />
                    </div>
                  </div>
                ))}
              </Slide>
            )}
          </div>
        </div>
        <div className="x-sm:w-full md:w-1/2 p-8 text-gray-400 text-right font-bold x-sm:text-sm md:text-lg 2xl:text-2xl space-y-2">
          <div className="space-y-4">
            {/* <h3>
              <span className="">النوع :</span>
              <span className="text-reghda-blue mr-8">للرجل</span>
            </h3> */}
            {/* <h3>
              <span className="">النوع العطري :</span>
              <span className="mr-8 text-reghda-blue">عطري</span>
            </h3> */}
            <h3>
              <span className="">الشحن :</span>
              <span className="mr-8 text-reghda-blue">
                <span>800 </span>
                <span>دج</span>
              </span>
            </h3>
            {/* <h3>
              <span className="">الحجم :</span>
              <span className="mr-8 text-reghda-blue">200مل</span>
            </h3> */}
          </div>

          {product.data && (
            <h3
              className={`x-sm:text-lg md:text-2xl 2xl:text-4xl text-center py-6 ${
                product &&
                product.data &&
                product.data.reduce_price &&
                "line-through text-red-500"
              }`}>
              <span className="">السعر :</span>
              <span
                className={`mr-8 ${
                  product && product.data && product.data.reduce_price
                    ? "text-red-500"
                    : "text-reghda-blue"
                }`}>
                {product.data.price} دج
              </span>
            </h3>
          )}
          {product && product.data && product.data.reduce_price && (
            <h3
              className={`x-sm:text-lg md:text-2xl 2xl:text-4xl text-center pb-4 `}>
              <span className=""> السعر الجديد :</span>
              <span className="mr-8 text-reghda-blue">
                {product.data.reduce_price} دج
              </span>
            </h3>
          )}

          {product.data && (
            <div className="flex flex-row items-center justify-center outline-none focus:outline-none">
              <Rating readOnly value={product.data.total_rating} />
            </div>
          )}
          <div className="flex x-sm:flex-col md:flex-row items-center x-sm:justify-center md:justify-between x-sm:space-y-6 md:space-x-14">
            <div className="flex flex-row items-center md:text-base 2xl:text-2xl">
              <p
                onClick={() => {
                  if (quantity < product.data.qty_available)
                    setQuantity(quantity + 1);
                }}
                className="text-reghda-blue p-2 border-1 border-pink-200 rounded-l-md cursor-pointer">
                <IconContext.Provider value={{ style: { color: "#d29e83" } }}>
                  <AiOutlinePlus></AiOutlinePlus>
                </IconContext.Provider>
              </p>
              <p className="text-reghda-blue p-4">{quantity}</p>
              <p
                onClick={() => {
                  if (quantity > 1) {
                    setQuantity(quantity - 1);
                  }
                }}
                className="text-reghda-blue p-2 border-1 border-pink-200 rounded-r-md cursor-pointer">
                <IconContext.Provider value={{ style: { color: "#d29e83" } }}>
                  <RiSubtractLine></RiSubtractLine>
                </IconContext.Provider>
              </p>
            </div>
            <button
              onClick={async () => {
                if (!store.isLogged) {
                  history.push("/login");
                  return;
                }
                // add to cart
                setLoading(true);
                let res = await addToCart({
                  user: getTheUserFromStorage().user_id,
                  product: product.data.id,
                  quantity: quantity,
                  override: true,
                });
                if (res.status === 201) {
                  setLoading(false);
                  history.push("/cart");
                } else if (res.status === 200) {
                  setLoading(false);
                  toast(res.data.message);
                }
              }}
              className="bg-reghda-blue text-white p-1 rounded-lg x-sm:text-xs 2xl:text-xl flex flex-row space-x-1 outline-none focus:outline-none">
              <IconContext.Provider
                value={{ size: "1.2em", style: { color: "#fff" } }}>
                <TiShoppingCart></TiShoppingCart>
              </IconContext.Provider>
              <span>أضف إلى السلة</span>
            </button>
            {data && (
              <button
                onClick={async () => {
                  if (!store.isLogged) {
                    history.push("/login");
                    return;
                  }
                  setLoading(true);
                  let res = await toggleToFavourite({
                    user: getTheUserFromStorage().user_id,
                    product: product.data.id,
                  });
                  if (res.status === 200) {
                    mutate(
                      `https://farasha.smartiniaclient.com/store/favourites/list/${
                        getTheUserFromStorage().user_id
                      }/${product.data.id}`
                    );
                    setLoading(false);
                  } else {
                    setLoading(false);
                    alert("something wrong,retry");
                  }
                }}
                className="x-sm:text-sm 2xl:text-xl flex flex-row items-center space-x-2 outline-none focus:outline-none">
                {data.Favourite ? (
                  <div className="x-sm:text-sm 2xl:text-xl flex flex-row items-center space-x-2">
                    <IconContext.Provider
                      value={{ size: "1.8em", style: { color: "#e35053" } }}>
                      <AiFillHeart></AiFillHeart>
                    </IconContext.Provider>
                    <span>حذف من المفضلة</span>
                  </div>
                ) : (
                  <div className="x-sm:text-sm 2xl:text-xl flex flex-row items-center space-x-2">
                    <IconContext.Provider
                      value={{ size: "1.8em", style: { color: "#d5d5d5" } }}>
                      <AiOutlineHeart></AiOutlineHeart>
                    </IconContext.Provider>
                    <span>اضف الى المفضلة</span>
                  </div>
                )}
              </button>
            )}
          </div>
          {product.data && (
            <h3 className="x-sm:text-base md:text-xl py-2">
              {product.data.is_available ? (
                <h1 className="text-green-300">متوفر</h1>
              ) : (
                <h1 className="text-red-500">غير متوفر</h1>
              )}
            </h3>
          )}
        </div>
      </div>
      <div className="text-right font-extrabold x-sm:px-4 md:px-28 space-y-6 text-reghda-blue">
        <h1 className="md:text-2xl 2xl:text-4xl">وصف</h1>
        {product.data && (
          <div className="border border-gray-200 rounded-md x-sm:p-2 md:p-4 2xl:p-8 x-sm:text-sm md:text-xl 2xl:text-2xl x-sm:leading-loose md:leading-loose 2xl:leading-raghda break-words">
            <p>{product.data.description}</p>
          </div>
        )}
      </div>

      {product.data && (
        <Reviews
          setLoading={setLoading}
          isLogged={store.isLogged}
          /* alreadyPaid={true} */
          productId={product.data.id}
        />
      )}
      <LoaderModal modalIsOpen={loading}></LoaderModal>
    </div>
  );
};

export default Details;
