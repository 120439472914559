import React, { useContext, useState, useEffect } from "react";
import { storeContext } from "./../../global/store";
import { IconContext } from "react-icons";
import { TiShoppingCart } from "react-icons/ti";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";

import { Link, useHistory, useLocation } from "react-router-dom";
import LoaderModal from "./../LoaderModal";

import useSWR, { mutate } from "swr";
import { fetcher } from "./../../services/fetcher";
import { getTheUserFromStorage } from "./../../services/auth";
import { toggleToFavourite } from "../../services/favourite";

const ProductCard = ({ product }) => {
  const { store, setStore } = useContext(storeContext);
  const { pathname } = useLocation();
  const [loading, setLoading] = useState(false);
  const [url, setUrl] = useState(
    `https://farasha.smartiniaclient.com/store/favourites/list/${1}/${
      product.id
    }`
  );

  const { data, error } = useSWR(url, fetcher);

  useEffect(() => {
    if (store.isLogged) {
      setUrl(
        `https://farasha.smartiniaclient.com/store/favourites/list/${
          getTheUserFromStorage().user_id
        }/${product.id}`
      );
    } else {
      setUrl(
        `https://farasha.smartiniaclient.com/store/favourites/list/${1}/${
          product.id
        }`
      );
    }
  }, [store.isLogged]);

  const history = useHistory();
  return (
    <div className="x-sm:p-1 sm:p-4 w-full border-2 border-gray-200 rounded-xl space-y-2">
      <div
        className="cursor-pointer"
        onClick={() => history.push(`/product/${product.slug}`, { product })}>
        {product.images && product.images.length && (
          <img src={product.images[0]} alt="product" />
        )}
      </div>
      <div className="x-sm:text-xs sm:text-base md:text-lg lg:text-xl 2xl:text-2xl text-center text-reghda-blue font-bold">
        <h1>{product.name}</h1>
      </div>
      <div className="x-sm:text-xs sm:text-base md:text-lg lg:text-xl 2xl:text-2xl text-center text-gray-400 font-bold">
        <div
          className={`flex flex-row justify-center items-center space-x-2 ${
            product.reduce_price && "line-through text-red-500"
          }`}>
          <span>دج</span>
          <span>{product.price}</span>
        </div>
        {product.reduce_price && (
          <div
            className={`flex flex-row justify-center items-center space-x-2`}>
            <span>دج</span>
            <span>{product.reduce_price}</span>
          </div>
        )}
      </div>

      <div className="flex flex-row items-center justify-around space-x-2">
        {!pathname.endsWith("favourite") && data && (
          <button
            onClick={async () => {
              if (!store.isLogged) {
                history.push("/login");
                return;
              }
              setLoading(true);
              let res = await toggleToFavourite({
                user: getTheUserFromStorage().user_id,
                product: product.id,
              });
              if (res.status === 200) {
                mutate(
                  `https://farasha.smartiniaclient.com/store/favourites/list/${
                    getTheUserFromStorage().user_id
                  }/${product.id}`
                );
                setLoading(false);
              } else {
                setLoading(false);
                alert("something wrong,retry");
              }
            }}
            className="x-sm:text-xxs md:text-sm flex flex-row items-center justify-around space-x-2 outline-none focus:outline-none">
            {data.Favourite ? (
              <div className="flex flex-row items-center justify-around space-x-2">
                <span className="text-gray-600 font-semibold x-sm:text-xxs md:text-sm">
                  حذف من المفضلة
                </span>
                <IconContext.Provider
                  value={{ size: "1.8em", style: { color: "#e35053" } }}>
                  <AiFillHeart></AiFillHeart>
                </IconContext.Provider>
              </div>
            ) : (
              <div className="flex flex-row items-center justify-around space-x-2">
                <span className="text-gray-600 font-semibold x-sm:text-xxs md:text-sm">
                  أضف الى المفضلة
                </span>
                <IconContext.Provider
                  value={{ size: "1.8em", style: { color: "#d5d5d5" } }}>
                  <AiOutlineHeart></AiOutlineHeart>
                </IconContext.Provider>
              </div>
            )}
          </button>
        )}
      </div>
      <div className="x-sm:text-xs sm:text-base md:text-lg lg:text-xl 2xl:text-2xl text-center">
        {product.is_available ? (
          <h1 className="text-green-300">متوفر</h1>
        ) : (
          <h1 className="text-red-500">غير متوفر</h1>
        )}
      </div>
      <LoaderModal modalIsOpen={loading}></LoaderModal>
    </div>
  );
};

export default ProductCard;
