import React, { useState } from "react";
import { IconButton } from "@material-ui/core";
import TwitterIcon from "@material-ui/icons/Twitter";
import YouTubeIcon from "@material-ui/icons/YouTube";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="footer text-blue-night font-extrabold flex flex-row-reverse flex-wrap justify-evenly items-center text-white font-extralight	font-extralight	mb-4 gap-2 ">
      <div className="w-screen  md:w-auto mb-4 flex justify-center">
        <img src="/images/logo.png" alt="logo" />
      </div>

      <Link to="/return" className="min-width-250 text-center my-4">
        سياسة الإرجاع
      </Link>
      <Link to="/privacy-policy" className="min-width-250 text-center my-4">
        سياسة الخصوصية
      </Link>
      <Link to="/rules" className="min-width-250 text-center my-4">
        الشروط و الأحكام
      </Link>
      <Link to="/questions" className="min-width-250 text-center my-4">
        الأسئلة المتكررة
      </Link>
      <div to="" className="min-width-250 text-center my-4">
        2021 &copy; جميع الحقوق محفوظة لفراشة اسانشن
      </div>
      <div className="min-width-250 text-center my-4">
        <IconButton style={{ color: "#160c3e" }}>
          <TwitterIcon />
        </IconButton>
        <IconButton style={{ color: "#160c3e" }}>
          <YouTubeIcon />
        </IconButton>
        <IconButton style={{ color: "#160c3e" }}>
          <FacebookIcon />
        </IconButton>
        <IconButton style={{ color: "#160c3e" }}>
          <InstagramIcon />
        </IconButton>
      </div>
    </div>
  );
};

export default Footer;
