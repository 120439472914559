import React, { useState } from "react";
import Navbar from "./../Navbar";
import Footer from "./../Footer";
import { Link } from "react-router-dom";

const RefundPolicyPage = () => {
  return (
    <div>
      <Navbar></Navbar>
      <div className="h-full text-center p-16 flex flex-col items-center justify-center x-sm:space-y-4 md:space-y-8 2xl:space-y-16 text-blue-night">
        <h1 className="font-extrabold text-orange x-sm:text-xs sm:text-base md:text-xl lg:text-2xl 2xl:text-5xl">
          سياسة الإرجاع
        </h1>
        <div className="text-right x-sm:text-xxs sm:text-sm md:text-lg lg:text-xl 2xl:text-3xl leading-loose space-y-6">
          <h1 className="font-extrabold text-orange leading-loose x-sm:text-xs sm:text-base md:text-xl lg:text-2xl 2xl:text-4xl break-words">
            البند الأول
          </h1>
          <p className="font-medium leading-loose break-words">
            استرجاع المبلغ المالي الذي دفعه مقابل منتج، دورة، مادة معينة على
            موقع فراشة اسانشن في حالة وحيدة فقط، وهي قبل بدأ الدورة، في حالة
            شرائك لدورة لم تبدأ بعد، أي أن مواد الدورة غير متوفرة على الموقع،
            يمكنك المطالبة باسترجاع المبلغ المالي. بمجرد أن يتم تنزيل مواد
            الدورة على الموقع، لن يكون ممكنا استرجاع المبلغ المالي
          </p>
          <h1 className="font-extrabold text-orange leading-loose x-sm:text-xs sm:text-base md:text-xl lg:text-2xl 2xl:text-4xl break-words">
            البند الثاني
          </h1>
          <p className="font-medium leading-loose break-words">
            لا يحق للمشترك استعادة الأموال في إحدى الحالات التالية <br />
            أ- وصول المادة كاملة للمشترك على الموقع <br />ب - العجز عن مشاهدة
            المادة بسبب ظروف خاصة بالمتدرب، سواء عدم توفر الأجهزة أو لمعاناته من
            مرض معين يمنع عليه أو يعيقه من متابعة المواد بشكل صحيح <br />
            ج- العجز عن إتمام المادة مهما كانت الأسباب، سواء لوفاة قريب أو لمرض
            معين أو لعدم الاقتناع بالمادة المطروحة أو غيرها من الأسباب
            <br />
            د- مخالفة أحد بنود هذه الاتفاقية <br />
            هـ - إفصاح الدعم الفني أن المشكلة في عدم تمكن المتدرب من مشاهدة
            المادة هي من عند المشترك و- عدم استفادة المشترك من المادة أو الدورة
            أو عدم الاقتناع بالمحتوى المطروح
          </p>
          <h1 className="font-extrabold text-orange leading-loose x-sm:text-xs sm:text-base md:text-xl lg:text-2xl 2xl:text-4xl break-words">
            البند الثالث
          </h1>
          <p className="font-medium leading-loose break-words">
            يلغى اشتراك المتدرب والزائر في حال كتابة تعليقات مسيئة وعبارات خادشة
            أو إرسال كلام نابي ومسيء عبر الإيميل أو إلى حساباتنا وارقامنا كما
            يمكن أن يوقعه هذا التصرف تحت المسائلة القانونية مع عدم رد المبلغ
            للمتدرب. ارسال عبارات مسيئة وخادشة يمكن أن يعرض صاحبه للحظر من
            الموقع بدون أي تعويض عن الدورات التي قد قام بشرائها مسبقا.
          </p>
          <h1 className="font-extrabold text-orange leading-loose x-sm:text-xs sm:text-base md:text-xl lg:text-2xl 2xl:text-4xl break-words">
            البند الرابع
          </h1>
          <p className="font-medium leading-loose break-words">
            يحق لموقعنا إيقاف اشتراك المتدرب مع عدم رد المبلغ المالي في حال
            مخالفته لأي من بنود هذه الاتفاقية
          </p>
          <h1 className="font-extrabold text-orange leading-loose x-sm:text-xs sm:text-base md:text-xl lg:text-2xl 2xl:text-4xl break-words">
            البند الخامس
          </h1>
          <p className="font-medium leading-loose break-words">
            في حال تم إرسال رابط مادة تقدم بمقابل مادي بالخطأ لمشترك في الموقع
            وهو لم يدفع مقابل هذه المادة نحتفظ بالحق في حذف هذه المادة من حسابه
            بدون إشعاره كما لا يحق له المطالبة بأي تعويض.
          </p>
          <h1 className="font-extrabold text-orange leading-loose x-sm:text-xs sm:text-base md:text-xl lg:text-2xl 2xl:text-4xl break-words">
            البند السادس
          </h1>
          <p className="font-medium leading-loose break-words">
            لا نتحمل أي مسؤولية بسبب تضرر المشترك من الدورات بسبب سوء فهمه لها
            أو بسبب تطبيقه الخاطئ لها أو للتمارين أو لأي سبب من الأسباب
          </p>
          <h1 className="font-extrabold text-orange leading-loose x-sm:text-xs sm:text-base md:text-xl lg:text-2xl 2xl:text-4xl break-words">
            البند السابع
          </h1>
          <p className="font-medium leading-loose break-words">
            نستقبل الحوالات عن طريق الحسابات البنكية لدينا فقط، والذين تجدون
            أرقامهم في صفحة الدفع لا يتحمل فراشة اسانشن للتدريب الوقوع في أي
            عملية نصب أو انتحال صفة وكيل كما لن يتم تعويض المشترك في حالة قيامه
            بإرسال المبلغ لشخص آخر غير معتمد من طرف موقع فراشة اسانشن للتدريب
          </p>
          <h1 className="font-extrabold text-orange leading-loose x-sm:text-xs sm:text-base md:text-xl lg:text-2xl 2xl:text-4xl break-words">
            البند الثامن
          </h1>
          <p className="font-medium leading-loose break-words">
            الخصومات على الدورات يمكن أن نقررها في أي وقت، في حالة شراء الدورة
            قبل الخصم، لا يحق للمشترك أن يطالب بتعويضه بالمبلغ عندما نطبق الخصم
            على نفس الدورة.
          </p>
          <h1 className="font-extrabold text-orange leading-loose x-sm:text-xs sm:text-base md:text-xl lg:text-2xl 2xl:text-4xl break-words">
            البند التاسع
          </h1>
          <p className="font-medium leading-loose break-words">
            يحق لنا نحن موقع فراشة اسانشن للتدريب تعديل أو أضافة أو حذف أي بند
            من بنود هذه الاتفاقية في أي وقت بدون إشعار المشتركين، لذلك يرجى
            الاطلاع على الشروط قبل القيام بأي عملية شراء
          </p>
          <h1 className="text-center font-extrabold text-orange leading-loose x-sm:text-xs sm:text-base md:text-xl lg:text-2xl 2xl:text-4xl break-words">
            سياسة الخصوصية
          </h1>
          <p className="text-center font-medium leading-loose break-words">
            يرجى الاطلاع على سياسة الخصوصية الخاصة بموقع من هنا
            <span className="text-reghda-blue">
              <Link className="underline text-orange" to="/privacy-policy">
                {" "}
                سياسة الخصوصية
              </Link>
            </span>
          </p>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default RefundPolicyPage;
