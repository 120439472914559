import React from "react";
import { Fade } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import { Link } from "react-router-dom";

const fadeImages = ["/images/الاحجار الطاقية.png", "/images/9iBH0ZXg.png"];

const Show = () => {
  return (
    <div className="h-full x-sm:p-4 md:px-20 space-y-8">
      <div className="bg-reghda-blue rounded-lg w-full text-center text-white px-2 py-2 md:text-xl lg:text-2xl 2xl:text-4xl">
        <h1>متجر الفراشة اسانشن</h1>
      </div>
      <div className="w-full h-full">
        <Fade>
          <Link to="/category/stones" className="each-fade">
            <div className="h-full w-full flex flex-row items-center justify-center">
              <img className="h-full w-3/4" src={fadeImages[0]} alt="hhh" />
            </div>
          </Link>
          <Link to="/category/books" className="each-fade">
            <div className="h-full w-full flex flex-row items-center justify-center">
              <img className="h-full w-3/4" src={fadeImages[1]} alt="hhhh" />
            </div>
          </Link>
        </Fade>
      </div>
    </div>
  );
};

export default Show;
